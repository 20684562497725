
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const base = namespace("base");
const service = namespace("service");
const marketing = namespace("marketing");
@Component({})
export default class MarketingReturn extends Vue {
  @base.Action getDictionaryList;
  @service.Action getOrderDetail;
  @marketing.Action commentRevisit;
  @marketing.Action getRevisitComment;
  splitThousands = splitThousands;
  dayjs = dayjs;
  emptyToLine = emptyToLine;
  sureClick = false;
  //计算属性
  get breadData() {
    return [
      { name: t("v210831.return-visit-evaluation"), path: "/marketing/return-appraise" },
      { name: this.rateDis ? t("v210831.evaluation-details") : t("v210831.return-visit") }
    ];
  }
  get rateDis() {
    return this.getRow.revisitStatus === 2 ? true : false;
  }
  tableService = [];
  tablePartsDetail = [];
  showTable = false;
  formRate = {
    serviceScore: null,
    workScore: null,
    ambienceScore: null,
    revisitCommentCode: null,
    subjectiveOpinion: ""
  };
  getRow = {
    revisitStatus: null,
    resultScore: null
  };
  getOrderDetial = {};
  created() {
    this.init();
  }
  mounted() {}
  groupIndex: number = 1;
  async init() {
    const { data } = await this.getRevisitComment({ revisitCommentCode: this.$route.params.revisitCommentCode });
    this.getRow = data;
    this.formRate.revisitCommentCode = this.$route.params.revisitCommentCode;
    // 拿订单数据
    this.formRate.serviceScore = data.serviceScore ? data.serviceScore : 0;
    this.formRate.workScore = data.workScore ? data.workScore : 0;
    this.formRate.ambienceScore = data.ambienceScore ? data.ambienceScore : 0;
    this.formRate.subjectiveOpinion = data.subjectiveOpinion;

    this.getOrderDetail({ orderNo: data.orderNo }).then(res => {
      const { partsDetail, serviceDetail } = res.data;
      const serviceCodeMap = {}; // 存放service和parts索引映射关系
      partsDetail.forEach((parts, index) => {
        if (parts.serviceCode) {
          if (serviceCodeMap[parts.serviceCode]) {
            serviceCodeMap[parts.serviceCode].push(index);
          } else {
            serviceCodeMap[parts.serviceCode] = [index];
          }
        }
      });
      serviceDetail.forEach(serviceItem => {
        if (serviceCodeMap[serviceItem.serviceCode]) {
          serviceItem.isGroup = true;
          serviceItem.groupServiceIndex = this.groupIndex;
          serviceCodeMap[serviceItem.serviceCode].forEach(i => {
            partsDetail[i].isGroup = true;
            partsDetail[i].groupServiceIndex = this.groupIndex;
          });
          this.groupIndex++;
        }
      });
      this.getOrderDetial = res.data;

      this.tablePartsDetail = partsDetail;
      this.tableService = serviceDetail;
    });
  }
  orShow() {
    this.showTable = !this.showTable;
  }
  footerSure(type) {
    if (this.formRate.serviceScore && this.formRate.workScore && this.formRate.ambienceScore) {
      this.commentRevisit({ ...this.formRate }).then(res => {
        this.$message.success(t("v210831.success"));
        this.$router.push("/marketing/return-appraise");
      });
    } else {
      this.sureClick = true;
    }
  }
  handleView(row) {
    let { href } = this.$router.resolve({
      path: `/service/view/${row.orderNo}`
    });
    window.open(href, "_blank");
  }
  //////
}
